import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { PageDataInterface } from "./pageDataReducer";
import { ConfigurationDevicesReducerInterface } from "./configurationDevicesReducer";
import { ConfigurationTeamsReducerInterface } from "./configurationTeamsReducer";
import { ConfigurationOperatorsReducerInterface } from "./configurationOperatorsReducer";
import { UserModel } from "../modules/auth/core/_models";
import { AdministrationSiteLocationsReducerInterface } from "./administrationSiteLocationReducer";
import { AdministrationDaqsReducerInterface } from "./administrationDaqsReducer";
import { AdministrationIdTagsReducerInterface } from "./administrationIdTagsReducer";
import { AdministrationAegisesReducerInterface } from "./administrationAegisesReducer";
import { AdministrationUsersReducerInterface } from "./administrationUsersReducer";
import { WFAInterface } from "./wfAnalysisMainReducer";

// USER
const selectReducerUser = (state: RootState): UserModel => state.userInfo;
const selectUserEmail = createSelector([selectReducerUser], (slice) => slice.email);
const selectUserFirstName = createSelector([selectReducerUser], (slice) => slice.first_name);
const selectUserLastName = createSelector([selectReducerUser], (slice) => slice.last_name);
const selectUserTheme = createSelector([selectReducerUser], (slice) => slice.user_preferences.theme);
const selectUserUnit = createSelector([selectReducerUser], (slice) => slice.user_preferences.exposure_unit);
const selectUserSiteLocation = createSelector(
    [selectReducerUser],
    (slice) => slice.session_preferences.selected_site_location,
);
const selectUserAllSiteLocations = createSelector(
    [selectReducerUser],
    (slice) =>
        slice.access.find((company) => company.slug === slice.session_preferences.selected_company)?.site_locations,
);
const selectUserCurrentSiteLocation = createSelector(
    [selectUserAllSiteLocations, selectUserSiteLocation],
    (siteLocations, selectedSiteLocation) => {
        return siteLocations?.find((site) => site.id === selectedSiteLocation);
    },
);
const selectUserLanguage = createSelector([selectReducerUser], (slice) => slice.user_preferences.language);
const selectSiteLocationThresholds = createSelector(
    [selectReducerUser],
    (slice) =>
        slice.access
            .find((company) => company.slug === slice.session_preferences.selected_company)
            ?.site_locations.find((siteLoc) => siteLoc.id === slice.session_preferences.selected_site_location)
            ?.site_location_preferences.exposure_threshold[slice.user_preferences.exposure_unit],
);
const selectUserUnitString = createSelector([selectReducerUser], (slice) =>
    slice.user_preferences.exposure_unit === "point" ? "" : "m/s²",
);
const selectUserCompany = createSelector([selectReducerUser], (slice) => slice.session_preferences.selected_company);
const selectUserCompanies = createSelector([selectReducerUser], (slice) => slice.access);
const selectUserCurrentCompany = createSelector(
    [selectUserCompanies, selectUserCompany],
    (companies, selectedCompanySlug) => {
        return companies.find((company) => company.slug === selectedCompanySlug) || null;
    },
);
const selectUserIsAdmin = createSelector(
    [selectReducerUser],
    (slice) => slice.access.find((company) => company.slug === slice.session_preferences.selected_company)?.is_admin,
);
const selectUserIsAreaManager = createSelector([selectReducerUser], (slice) => {
    const company = slice.access.find((comp) => comp.slug === slice.session_preferences.selected_company);
    if (!company) {
        return false;
    }
    const siteLocation = company.site_locations.find(
        (siteLoc) => siteLoc.id === slice.session_preferences.selected_site_location,
    );
    if (!siteLocation) {
        return false;
    }
    return siteLocation.permissions === "Area Manager";
});
const selectUserIsManager = createSelector([selectReducerUser], (slice) => {
    const company = slice.access.find((comp) => comp.slug === slice.session_preferences.selected_company);
    if (!company) {
        return false;
    }
    const siteLocation = company.site_locations.find(
        (siteLoc) => siteLoc.id === slice.session_preferences.selected_site_location,
    );
    if (!siteLocation) {
        return false;
    }
    return siteLocation.permissions === "Manager";
});
export {
    selectUserEmail,
    selectUserSiteLocation,
    selectUserUnit,
    selectUserTheme,
    selectUserFirstName,
    selectUserLastName,
    selectUserAllSiteLocations,
    selectUserLanguage,
    selectSiteLocationThresholds,
    selectUserUnitString,
    selectUserCompanies,
    selectUserCompany,
    selectUserIsAdmin,
    selectUserIsAreaManager,
    selectUserIsManager,
    selectUserCurrentSiteLocation,
    selectUserCurrentCompany,
};

// PAGE DATA
const selectReducerPageData = (state: RootState): PageDataInterface => state.pageData;
const selectPageDataBreadcrumbs = createSelector([selectReducerPageData], (slice) => slice.pageBreadcrumbs);
const selectPageDataTitle = createSelector([selectReducerPageData], (slice) => slice.pageTitle);
export { selectPageDataBreadcrumbs, selectPageDataTitle };

// CONFIGURATION TEAMS
const selectReducerConfigurationTeams = (state: RootState): ConfigurationTeamsReducerInterface =>
    state.configurationTeams;
const selectConfigurationTeamsError = createSelector([selectReducerConfigurationTeams], (slice) => slice.error);
const selectConfigurationTeamsLoading = createSelector([selectReducerConfigurationTeams], (slice) => slice.loading);
const selectConfigurationTeamsCurrent = createSelector([selectReducerConfigurationTeams], (slice) => slice.currentTeam);
const selectConfigurationTeamsActive = createSelector([selectReducerConfigurationTeams], (slice) => slice.teams.active);
const selectConfigurationTeamsInactive = createSelector(
    [selectReducerConfigurationTeams],
    (slice) => slice.teams.inactive,
);
export {
    selectConfigurationTeamsError,
    selectConfigurationTeamsLoading,
    selectConfigurationTeamsActive,
    selectConfigurationTeamsInactive,
    selectConfigurationTeamsCurrent,
};

// CONFIGURATION OPERATORS
const selectReducerConfigurationOperators = (state: RootState): ConfigurationOperatorsReducerInterface =>
    state.configurationOperators;
const selectConfigurationOperatorsError = createSelector([selectReducerConfigurationOperators], (slice) => slice.error);
const selectConfigurationOperatorsLoading = createSelector(
    [selectReducerConfigurationOperators],
    (slice) => slice.loading,
);
const selectConfigurationOperatorsCurrent = createSelector(
    [selectReducerConfigurationOperators],
    (slice) => slice.currentOperator,
);
const selectConfigurationOperatorsActive = createSelector(
    [selectReducerConfigurationOperators],
    (slice) => slice.operators.active,
);
const selectConfigurationOperatorsInactive = createSelector(
    [selectReducerConfigurationOperators],
    (slice) => slice.operators.inactive,
);
export {
    selectConfigurationOperatorsError,
    selectConfigurationOperatorsLoading,
    selectConfigurationOperatorsCurrent,
    selectConfigurationOperatorsActive,
    selectConfigurationOperatorsInactive,
};

// CONFIGURATION DEVICES
const selectReducerConfigurationDevices = (state: RootState): ConfigurationDevicesReducerInterface =>
    state.configurationDevices;
const selectConfigurationDevicesError = createSelector([selectReducerConfigurationDevices], (slice) => slice.error);
const selectConfigurationDevicesLoading = createSelector([selectReducerConfigurationDevices], (slice) => slice.loading);
const selectConfigurationDevicesAegisesActive = createSelector(
    [selectReducerConfigurationDevices],
    (slice) => slice.aegises.active,
);
const selectConfigurationDevicesAegiesesInactive = createSelector(
    [selectReducerConfigurationDevices],
    (slice) => slice.aegises.inactive,
);
const selectConfigurationDevicesAegisesCurrent = createSelector(
    [selectReducerConfigurationDevices],
    (slice) => slice.currentAegis,
);
const selectConfigurationDevicesIdTagsActive = createSelector(
    [selectReducerConfigurationDevices],
    (slice) => slice.idTags.active,
);
const selectConfigurationDevicesIdTagsInactive = createSelector(
    [selectReducerConfigurationDevices],
    (slice) => slice.idTags.inactive,
);
const selectConfigurationDevicesIdTagsCurrent = createSelector(
    [selectReducerConfigurationDevices],
    (slice) => slice.currentIdTag,
);
const selectConfigurationDevicesDaqsActive = createSelector(
    [selectReducerConfigurationDevices],
    (slice) => slice.daqs.active,
);
const selectConfigurationDevicesDaqsInactive = createSelector(
    [selectReducerConfigurationDevices],
    (slice) => slice.daqs.inactive,
);
const selectConfigurationDevicesDaqsCurrent = createSelector(
    [selectReducerConfigurationDevices],
    (slice) => slice.currentDaq,
);
export {
    selectConfigurationDevicesError,
    selectConfigurationDevicesLoading,
    selectConfigurationDevicesAegisesActive,
    selectConfigurationDevicesAegiesesInactive,
    selectConfigurationDevicesAegisesCurrent,
    selectConfigurationDevicesIdTagsActive,
    selectConfigurationDevicesIdTagsInactive,
    selectConfigurationDevicesIdTagsCurrent,
    selectConfigurationDevicesDaqsActive,
    selectConfigurationDevicesDaqsInactive,
    selectConfigurationDevicesDaqsCurrent,
};

// ADMINISTRATION SITE LOCATION
const selectReducerAdministrationSiteLocation = (state: RootState): AdministrationSiteLocationsReducerInterface =>
    state.administrationSiteLocation;
const selectAdministrationSiteLocationsError = createSelector(
    [selectReducerAdministrationSiteLocation],
    (slice) => slice.error,
);
const selectAdministrationSiteLocationsLoading = createSelector(
    [selectReducerAdministrationSiteLocation],
    (slice) => slice.loading,
);
const selectAdministrationSiteLocationsTimezones = createSelector(
    [selectReducerAdministrationSiteLocation],
    (slice) => slice.timezones,
);
const selectAdministrationSiteLocationsCurrentSiteLocation = createSelector(
    [selectReducerAdministrationSiteLocation],
    (slice) => slice.currentSiteLocation,
);
const selectAdministrationSiteLocationsAll = createSelector(
    [selectReducerAdministrationSiteLocation],
    (slice) => slice.siteLocations,
);

export {
    selectAdministrationSiteLocationsError,
    selectAdministrationSiteLocationsLoading,
    selectAdministrationSiteLocationsTimezones,
    selectAdministrationSiteLocationsCurrentSiteLocation,
    selectAdministrationSiteLocationsAll,
};

// ADMINISTRATION DAQS
const selectReducerAdministrationDaqs = (state: RootState): AdministrationDaqsReducerInterface =>
    state.administrationDaqs;
const selectAdministrationDaqsError = createSelector([selectReducerAdministrationDaqs], (slice) => slice.error);
const selectAdministrationDaqsLoading = createSelector([selectReducerAdministrationDaqs], (slice) => slice.loading);
const selectAdministrationDaqsCurrentDaq = createSelector(
    [selectReducerAdministrationDaqs],
    (slice) => slice.currentDaq,
);
const selectAdministrationDaqs = createSelector([selectReducerAdministrationDaqs], (slice) => slice.daqs);

export {
    selectAdministrationDaqsError,
    selectAdministrationDaqsLoading,
    selectAdministrationDaqsCurrentDaq,
    selectAdministrationDaqs,
};

// ADMINISTRATION AEGISES
const selectReducerAdministrationAegises = (state: RootState): AdministrationAegisesReducerInterface =>
    state.administrationAegises;
const selectAdministrationAegisesError = createSelector([selectReducerAdministrationAegises], (slice) => slice.error);
const selectAdministrationAegisesLoading = createSelector(
    [selectReducerAdministrationAegises],
    (slice) => slice.loading,
);
const selectAdministrationAegisesCurrentAegis = createSelector(
    [selectReducerAdministrationAegises],
    (slice) => slice.currentAegis,
);
const selectAdministrationAegises = createSelector([selectReducerAdministrationAegises], (slice) => slice.aegises);

export {
    selectAdministrationAegisesError,
    selectAdministrationAegisesLoading,
    selectAdministrationAegisesCurrentAegis,
    selectAdministrationAegises,
};

// ADMINISTRATION ID TAGS
const selectReducerAdministrationIdTags = (state: RootState): AdministrationIdTagsReducerInterface =>
    state.administrationIdTags;
const selectAdministrationIdTagsError = createSelector([selectReducerAdministrationIdTags], (slice) => slice.error);
const selectAdministrationIdTagsLoading = createSelector([selectReducerAdministrationIdTags], (slice) => slice.loading);

const selectAdministrationIdTagsCurrentIdTag = createSelector(
    [selectReducerAdministrationIdTags],
    (slice) => slice.currentIdTag,
);

const selectAdministrationIdTags = createSelector([selectReducerAdministrationIdTags], (slice) => slice.idTags);

export {
    selectAdministrationIdTagsError,
    selectAdministrationIdTagsLoading,
    selectAdministrationIdTagsCurrentIdTag,
    selectAdministrationIdTags,
};

// ADMINISTRATION USERS
const selectReducerAdministrationUsers = (state: RootState): AdministrationUsersReducerInterface =>
    state.administrationUsers;
const selectAdministrationUsersError = createSelector([selectReducerAdministrationUsers], (slice) => slice.error);
const selectAdministrationUsersLoading = createSelector([selectReducerAdministrationUsers], (slice) => slice.loading);

const selectAdministrationUsersCurrentUser = createSelector(
    [selectReducerAdministrationUsers],
    (slice) => slice.currentUser,
);

const selectAdministrationUsers = createSelector([selectReducerAdministrationUsers], (slice) => slice.users);

const selectAdministrationUsersGroups = createSelector([selectReducerAdministrationUsers], (slice) => slice.groups);

export {
    selectAdministrationUsersError,
    selectAdministrationUsersLoading,
    selectAdministrationUsersCurrentUser,
    selectAdministrationUsers,
    selectAdministrationUsersGroups,
};

// WORKFORCE ANALYSIS
const selectReducerWFA = (state: RootState): WFAInterface => state.workforceAnalysis;
const selectWFA = createSelector([selectReducerWFA], (slice) => slice.wfa);
const selectWFATeam = createSelector([selectReducerWFA], (slice) => slice.wfaTeam);
const selectWFAOperator = createSelector([selectReducerWFA], (slice) => slice.wfaOperator);
const selectWFAExposure = createSelector([selectReducerWFA], (slice) => slice.wfaExposure);
const selectWFAError = createSelector([selectReducerWFA], (slice) => slice.error);
const selectWFALoading = createSelector([selectReducerWFA], (slice) => slice.loading);

export { selectWFA, selectWFATeam, selectWFAOperator, selectWFAExposure, selectWFALoading, selectWFAError };
