import axios, { AxiosResponse } from "axios";

/*
|--------------------------------------------------------------------------
| WORKFORCE
|--------------------------------------------------------------------------
*/
export const getWorkforceAnalysis = (
    company: string,
    siteLocation: number,
    startDate: string,
    endDate: string,
): Promise<AxiosResponse<WorkforceAnalysisInterface>> => {
    return axios.get(`/companies/${company}/site-locations/${siteLocation}/workforce-analysis/workforce/exposures/`, {
        params: {
            start_date: startDate,
            end_date: endDate,
        },
    });
};

export interface WorkforceAnalysisTeamOverviewInterface {
    operators_monitored: number;
    exposures_monitored: number;
    average_exposure_duration: number;
    average_measurement_duration: number;
    average_exposure_value: number;
    highest_exposure_value: number;
    under_eav: number;
    over_eav: number;
    over_elv: number;
    team_id: number;
    team_name: string;
}

export interface WorkforceAnalysisInterface {
    team_overviews: WorkforceAnalysisTeamOverviewInterface[];
    workforce_overview: {
        teams_monitored: number;
        operators_monitored: number;
        exposures_monitored: number;
        average_exposure_duration: number;
        average_measurement_duration: number;
        average_exposure_value: number;
        highest_exposure_value: number;
        under_eav: number;
        over_eav: number;
        over_elv: number;
    };
}

/*
|--------------------------------------------------------------------------
| TEAM
|--------------------------------------------------------------------------
*/
export const getTeamAnalysis = (
    company: string,
    siteLocation: number,
    team: number,
    startDate: string,
    endDate: string,
): Promise<AxiosResponse<TeamAnalysisInterface>> => {
    return axios.get(
        `/companies/${company}/site-locations/${siteLocation}/workforce-analysis/teams/${team}/exposures/`,
        {
            params: {
                start_date: startDate,
                end_date: endDate,
            },
        },
    );
};

export interface TeamAnalysisOperatorOverviewInterface {
    exposures_monitored: number;
    average_exposure_duration: number;
    average_measurement_duration: number;
    average_exposure_value: number;
    highest_exposure_value: number;
    under_eav: number;
    over_eav: number;
    over_elv: number;
    operator_id: number;
    badge_id: string;
    team_id: number;
}

export interface TeamAnalysisInterface {
    overview: {
        operators_monitored: number;
        exposures_monitored: number;
        average_exposure_duration: number;
        average_measurement_duration: number;
        average_exposure_value: number;
        highest_exposure_value: number;
        under_eav: number;
        over_eav: number;
        over_elv: number;
    };
    team_details: {
        team_id: number;
        team_name: string;
        site_location_id: number;
        site_location_name: string;
    };
    operator_overviews: TeamAnalysisOperatorOverviewInterface[];
}

/*
|--------------------------------------------------------------------------
| OPERATOR
|--------------------------------------------------------------------------
*/
export const getOperatorAnalysis = (
    company: string,
    siteLocation: number,
    operator: number,
    startDate: string,
    endDate: string,
): Promise<AxiosResponse<OperatorAnalysisInterface>> => {
    return axios.get(
        `/companies/${company}/site-locations/${siteLocation}/workforce-analysis/operators/${operator}/exposures/`,
        {
            params: {
                start_date: startDate,
                end_date: endDate,
            },
        },
    );
};

export interface ExposureOverviewInterface {
    exposure_id: string;
    exposure_value: number;
    exposure_duration: number;
    duration: number;
    exposure_date_start: string;
    operator_id: number;
}

export interface OperatorAnalysisInterface {
    operator_details: {
        operator_id: number;
        badge_id: string;
        team_id: number;
        team_name: string;
        site_location_id: number;
        site_location_name: string;
    };
    overview: {
        exposures_monitored: number;
        average_exposure_duration: number;
        average_measurement_duration: number;
        average_exposure_value: number;
        highest_exposure_value: number;
        under_eav: number;
        over_eav: number;
        over_elv: number;
    };
    exposures_list: ExposureOverviewInterface[];
}

/*
|--------------------------------------------------------------------------
| GRAPHS + EXPOSURES
|--------------------------------------------------------------------------
*/
export const getExposureAnalysis = (
    company: string,
    siteLocation: number,
    operator: number,
    exposure: string,
): Promise<AxiosResponse<ExposureAnalysisInterface>> => {
    return axios.get(
        `/companies/${company}/site-locations/${siteLocation}/workforce-analysis/operators/${operator}/exposures/${exposure}/`,
    );
};

export interface ExposureDetails {
    timezone: string;
    timezone_offset: number;
    exposure_data: {
        peak: {
            highest: number[];
            x: number[];
            y: number[];
            z: number[];
        };
        rms: {
            x: number[];
            y: number[];
            z: number[];
            sum: number[];
        };
    };
    grip_strength: number[];
    highest_frequencies: {
        average: number[];
        x: number[];
        y: number[];
        z: number[];
    };
    timestamp: number[];
    vibration_exposure: {
        a8?: number[];
        a12?: number[];
        a24?: number[];
        point?: number[];
    };
}

export interface HardwareDeviceInterface {
    id: number | null;
    serial_no: string | null;
    in_site_location: boolean;
}

export interface ExposureOverviewWithHardwareInterface extends ExposureOverviewInterface {
    hardware: {
        aegis: HardwareDeviceInterface;
        daq: HardwareDeviceInterface;
        id_tag: HardwareDeviceInterface;
    };
}

export interface ExposureAnalysisInterface {
    overview: ExposureOverviewWithHardwareInterface;
    operator_details: {
        operator_id: number;
        badge_id: string;
        team_id: number;
        team_name: string;
        site_location_id: number;
        site_location_name: string;
    };
    exposure_details: ExposureDetails;
}

export const getCsvData = (
    company: string,
    siteLocation: number,
    operator: number,
    exposure: string,
): Promise<AxiosResponse> => {
    return axios.get(
        `/companies/${company}/site-locations/${siteLocation}/workforce-analysis/operators/${operator}/exposures/${exposure}/download/`,
        {
            responseType: "blob",
        },
    );
};
