import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    ExposureAnalysisInterface,
    OperatorAnalysisInterface,
    TeamAnalysisInterface,
    WorkforceAnalysisInterface,
    getWorkforceAnalysis,
    getTeamAnalysis,
    getOperatorAnalysis,
    getExposureAnalysis,
} from "../modules/workforce-analysis/_requests";

export interface WFAInterface {
    wfa: WorkforceAnalysisInterface | undefined;
    wfaTeam: TeamAnalysisInterface | undefined;
    wfaOperator: OperatorAnalysisInterface | undefined;
    wfaExposure: ExposureAnalysisInterface | undefined;
    error: AxiosError | undefined;
    loading: boolean;
}

const initialState: WFAInterface = {
    wfa: undefined,
    wfaTeam: undefined,
    wfaOperator: undefined,
    wfaExposure: undefined,
    error: undefined,
    loading: false,
};

export const getWFA = createAsyncThunk(
    "wfAnalysisMain/getWorkforceAnalysis",
    async (obj: { company: string; siteLocation: number; startDate: string; endDate: string }, thunkAPI) => {
        try {
            const result = await getWorkforceAnalysis(obj.company, obj.siteLocation, obj.startDate, obj.endDate);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getWFATeam = createAsyncThunk(
    "wfAnalysisMain/getTeamAnalysis",
    async (
        obj: { company: string; siteLocation: number; team: number; startDate: string; endDate: string },
        thunkAPI,
    ) => {
        try {
            const result = await getTeamAnalysis(obj.company, obj.siteLocation, obj.team, obj.startDate, obj.endDate);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getWFAOperator = createAsyncThunk(
    "wfAnalysisMain/getOperatorAnalysis",
    async (
        obj: { company: string; siteLocation: number; operator: number; startDate: string; endDate: string },
        thunkAPI,
    ) => {
        try {
            const result = await getOperatorAnalysis(
                obj.company,
                obj.siteLocation,
                obj.operator,
                obj.startDate,
                obj.endDate,
            );
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

export const getWFAExposure = createAsyncThunk(
    "wfAnalysisMain/getExposureAnalysis",
    async (obj: { company: string; siteLocation: number; operator: number; exposure: string }, thunkAPI) => {
        try {
            const result = await getExposureAnalysis(obj.company, obj.siteLocation, obj.operator, obj.exposure);
            return result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    },
);

const workforceAnalysisSlice = createSlice({
    name: "wfAnalysisMain",
    initialState,
    reducers: {
        resetState() {
            return initialState;
        },
        setError: (state, action: PayloadAction<AxiosError>) => {
            return { ...state, error: action.payload };
        },
    },
    extraReducers: (builder) => {
        // wfa
        builder.addCase(getWFA.pending, (state) => {
            return { ...state, wfa: initialState.wfa, error: undefined, loading: true };
        });
        builder.addCase(getWFA.fulfilled, (state, action) => {
            return { ...state, wfa: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getWFA.rejected, (state, action) => {
            return { ...state, wfa: initialState.wfa, error: action.payload as AxiosError, loading: false };
        });
        // wfaTeam
        builder.addCase(getWFATeam.pending, (state) => {
            return { ...state, wfaTeam: initialState.wfaTeam, error: undefined, loading: true };
        });
        builder.addCase(getWFATeam.fulfilled, (state, action) => {
            return { ...state, wfaTeam: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getWFATeam.rejected, (state, action) => {
            return { ...state, wfaTeam: initialState.wfaTeam, error: action.payload as AxiosError, loading: false };
        });
        // wfaOperator
        builder.addCase(getWFAOperator.pending, (state) => {
            return { ...state, wfaOperator: initialState.wfaOperator, error: undefined, loading: true };
        });
        builder.addCase(getWFAOperator.fulfilled, (state, action) => {
            return { ...state, wfaOperator: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getWFAOperator.rejected, (state, action) => {
            return {
                ...state,
                wfaOperator: initialState.wfaOperator,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
        // wfaExposure
        builder.addCase(getWFAExposure.pending, (state) => {
            return { ...state, wfaExposure: initialState.wfaExposure, error: undefined, loading: true };
        });
        builder.addCase(getWFAExposure.fulfilled, (state, action) => {
            return { ...state, wfaExposure: action.payload, error: undefined, loading: false };
        });
        builder.addCase(getWFAExposure.rejected, (state, action) => {
            return {
                ...state,
                wfaExposure: initialState.wfaExposure,
                error: action.payload as AxiosError,
                loading: false,
            };
        });
    },
});

export const { resetState, setError } = workforceAnalysisSlice.actions;

export default workforceAnalysisSlice.reducer;
